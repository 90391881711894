import React from 'react';
import './SkillsDetail.css';
import TechCard from '../TechCard/TechCard';

const SkillsDetail = props => {
    const skillsCard = props.skills.map( obj => {
        return <TechCard image={obj.img} text={obj.text} altText={obj.altText}></TechCard>
    });

    return (
        <div id="SkillsDetail">
            <div id="SkillsetText">{props.skillset}</div>
            {skillsCard}
        </div>
    )
}

export default SkillsDetail;
