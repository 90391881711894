import React from 'react';
import classes from './ContactCard.module.css';
import images from '../../res/images/images';

const ContactCard = (props) => {
    const email = props.showEmail ? 'Email: khileshx@gmail.com' : '';
    return (
        <div className={classes.ContactBox}>
        <span className={classes.email}>{email} </span>
        <div className={classes.ContactCard}>
            <a className={classes.weblinks} target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/khilesh">  <img src={images.linkedin} className={classes.imgicon} alt="Linkedin"/></a>
        
            <a className={classes.weblinks} target="_blank" rel="noreferrer" href="https://www.github.com/curexcode"> <img src={images.github}  className={classes.imgicon}alt="Github"/></a>
            <a className={classes.weblinks} target="_blank" rel="noreferrer" href="https://www.hackerrank.com/Curex"> <img src={images.hackerrank} className={classes.imgicon} alt="Hackerrank"/></a>
        </div>
        </div>
    )
}

export default ContactCard;
// <a className={classes.email} href="https://www.gmail.com"> <img src={images.email} className={classes.mailicon} alt="Email"/> </a>