import React, {useState, useRef} from 'react';
import './Contact.css';
import MessageACK from '../MessageACK/MessageACK';
import ContactCard from '../../component/ContactCard/ContactCard';
import axios from '../../AxiosCustom';
// import { platform } from 'chart.js';
import getUserMetaData from '../../Utility';

function Contact() {

    const [sendbtnClicked, setSendbtnClicked] =  useState(false);
    const nameEl = useRef(null);
    const emailEl = useRef(null);
    const purposeEl = useRef(null);
    const msgEl = useRef(null)
    let [submitSuccess, setSubmitSuccess] = useState(false);
    let MessageBox = null; 

    const sendbtnClickHandler = (e) => {
        e.preventDefault();
        setSendbtnClicked(!sendbtnClicked);
        let name, email, purpose, msg;
        name = nameEl.current.value;
        purpose = purposeEl.current.value;
        email = emailEl.current.value;
        msg = msgEl.current.value;
    
        // Making network requests
        (async function makeRequests(){
            let metadata = await getUserMetaData();
            
            axios.post('/messages.json', {
                name: name,
                purpose: purpose,
                email: email,
                message: msg,
                metadata: metadata
            }).then(res => {
                setSubmitSuccess(true);
            }).catch((e) => {
                setSubmitSuccess(false);
                alert('Error Sending Message');
            })
        })();

    }

    if (submitSuccess){
        MessageBox = (
        <div id="MessageACK">
            <MessageACK onBtnClick={sendbtnClickHandler} submitSuccess={submitSuccess}/>
        </div>)
        setTimeout(() => setSubmitSuccess(false), 6000);
    }
    

    return (
        <>
            <h4 id="contactheader">Contact Me</h4>
            <form id="contactform">
                <label  id="namelbl" >Name</label>
                <input type="text" id="name" name="name" ref={nameEl}></input>
                <label id="emaillbl">Email</label>
                <input type="text" id="email" name="email" ref={emailEl}></input>
                <label id="purposelbl">Purpose</label>
                <input type="text" id="purpose" name="purpose" ref={purposeEl}></input>
                <label id="msglbl">Message</label>
                <textarea name="message" id="contactmsg"  ref={msgEl}></textarea>
                <button type="submit" id="sendbtn" onClick={sendbtnClickHandler}>Send</button>
            </form>
            {MessageBox}
            <div id="contcard">
                <ContactCard showEmail={true}/>
            </div>
        </>
    )
}

export default Contact;