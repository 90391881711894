import React from 'react';
import TechCard from '../TechCard/TechCard';
import images from '../../res/images/images';
import './LangDetail.css';



function LangDetail(props) {
    // let style = {
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     width: '50px',
    //     height: '50px',
    //     border: '2px black solid'
    // }
    return (
        <div id="LangDetail">
            <div id="Title" >Programming Languages</div>
            <div className="Description1" >Primary: </div>
            <div className="Description2" >Secondary: </div>
            <div className="Description3" >Some Exposure: </div>
            <TechCard className="js" image={images.js}/>
            <TechCard className="python" image={images.python}/>
            <TechCard className="java" image={images.java}/>
            <TechCard className="cpp" image={images.cpp}/>
            <TechCard className="csharp" image={images.csharp}/>
            <TechCard className="clang" image={images.clang}/>
            <TechCard className="php" image={images.php}/>
            <TechCard className="bash" image={images.bash}/>
        </div>
    )
}

export default LangDetail;
