import React from 'react';
import images from '../../res/images/images';
import './Projects.css';

export default function Projects() {
    return (
        <div id="Projects">
            <h4>Work In Progress</h4>
            <img src={images.cpgif} alt="..."/>
            <a href="https://www.github.com/curexcode" target="_blank" rel="noreferrer" id="link">

            <div id="github-msg">
            <span>Checkout My Github</span>
            <img src={images.github} id="github-logo" alt="Github"/>
            </div>
            </a>
        </div>
    )
}
