import React, {useState} from 'react';
import './Skills.css';
import {Pie} from 'react-chartjs-2';
// import TechCard from '../TechCard/TechCard';
import images from '../../res/images/images';
import SkillsDetail from '../SkillsDetail/SkillsDetail';
import LangDetail from '../LangDetail/LangDetail';
import axios from '../../AxiosCustom';
import getUserMetaData from '../../Utility';

export default function Skills() {

    const [currentSkills, setCurrentSkills] = useState('Click on legends.');
    let skillset = null;
    let clickMsg = <span id="hint"><span id="arrow"> ⇧ </span>Click here</span>;


    const frontend = [
        {
            img: images.react,
            text: 'React',
            alt: 'React'
        }, 
        {
            img: images.redux,
            text: 'Redux',
            alt: 'Redux'
        }, 
        {
            img: images.html,
            text: 'HTML',
            alt: ''
        },
        {
            img: images.css,
            text: 'CSS',
            alt: 'CSS',
        },
        {
            img: images.js,
            text: 'JavaScript',
            alt: 'JavaScript',
        }
    ];

    const backend = [
        {
            img: images.django,
            text: 'Django',
            alt: 'Django'
        },
        {
            img: images.express,
            text: 'Express',
            alt: 'Express'
        },
        {
            img: images.docker,
            text: 'Docker',
            alt: 'Docker'
        },
        {
            img: images.firebase,
            text: 'Firebase',
            alt: 'Firebase'
        },
        {
            img: images.rest,
            text: 'REST',
            alt: 'REST'
        },
        {
            img: images.node,
            text: 'Node',
            alt: 'Node'
        }
    ]

    const mobile = [
        {
            img: images.android,
            text: 'Android',
            alt: 'Android'
        },
        {
            img: images.reactnative,
            text: 'ReactNative',
            alt: 'ReactNative'
        }
    ]

    const others = [
        {
            img: images.git,
            text: 'Git',
            alt: 'git'
        },
        {
            img: images.linux,
            text: 'linux',
            alt:'linux'
        },
        {        
            img: images.bash,
            text: 'Bash',
            alt: 'bash'
        },
        {
            img: images.vim,
            text: 'Vim',
            alt: 'Vim',
        },
        {
            img: images.vscode,
            text: 'vscode',
            alt: 'vscode',
        },
        {
            img: images.tmux,
            text: 'Tmux',
            alt: 'tmux',
        }




    ]


    // const frontendCards = frontend.map( obj => {
    //     return <TechCard image={obj.img} text={obj.text} altText={obj.altText}></TechCard>
    // });

    switch(currentSkills){
        case 'Frontend':
            skillset= frontend;
            break;
        case 'Backend':
            skillset= backend;
            break;
        case 'Mobile':
            skillset= mobile;
            break;
        case 'Others':
            skillset= others;
            break;
        default:
            skillset = [];
    }

    if(currentSkills !== 'Click on legends.'){
        clickMsg = null;
    }

    
    async function makeNetworkRequests() { 
     
            let pageVisited = window.localStorage.getItem('tpv');
            
            // If pageVisited is null of false
            if(!pageVisited){
                let metadata = await getUserMetaData();
                await axios.post('/tech_page_visits.json', metadata);
                window.localStorage.setItem('tpv', true);
                // console.log('done')
            }
    }

    let chartData = {
        labels: ['Frontend', 'Backend', 'Mobile', 'Others'],
        datasets:[
          { label:'Skills',
            data:[
              33,
              33,
              11,
              23
            ],
            backgroundColor:[
              'rgba(247, 49, 160, 0.6)',
              'rgba(83, 51, 237, 0.6)',
              'rgba(148, 198, 1, 0.6)',
              'rgba(0, 0, 139, 0.6)',
            ]
          }
        ]
      }

    return (
        <div id="SkillsContainer">
            <div id="ChartArea">
                <Pie
                    data={chartData}
                    options={{
                        title:{
                            display:'Some title',
                            text:'Skills Pie Chart',
                            fontSize:25,
                            fontColor: 'white',
                        },
                    tooltips:{
                        enabled:true,
                        // custom: (e, stuff) => {
                        //     console.log(e);
                        //     let element = document.getElementById('chartjs-tooltip');
                        //     console.log(stuff)
                        // }
                    },
                    legend:{
                        display: true,
                        position:'bottom',
                        labels:{
                            fontColor: 'white',
                            fontSize: 15
                        },
                        onClick: (e, legendItem) => {
                            setCurrentSkills(legendItem.text);
                            makeNetworkRequests();
                        }

                      },
                    }}
                />
                {clickMsg}
            </div>
            <div id="TechDetail">
                <SkillsDetail skillset={currentSkills} skills={skillset}/>
            </div>

            <div id="LanguagesDetail">
                <LangDetail/>
            </div>
        </div>

    )
}
