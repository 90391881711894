import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './Greetings.css';
import ContactCard from '../../component/ContactCard/ContactCard';
import axios from '../../AxiosCustom';
import getUserMetaData from '../../Utility';

export default function Content() {
    const [counter, setCounter] = useState(0);
    const greetings = ['Hello','Bonjour', 'नमस्ते', 'Hola', 'こんにちは', 'Hallo', '你好'];
    const techlink = <Link to="/tech" id="mylink">here.</Link>;
    const contactlink = <Link to="/contact" id="mylink">here.</Link>


    useEffect(() => {
        const intervalHandle =  setTimeout( () => {
                // console.log('Updating counter...');
                if(counter < greetings.length - 1){
                    setCounter(counter + 1);
                }
                else   
                    setCounter(0);
                }
        , 3000);



        return () => clearTimeout(intervalHandle);

    });

    useEffect( () => {
        //hpv = home page visited
        (async function doAsyncStuff() { 
            // console.log('Getting stuff...');

            let pageVisited = window.localStorage.getItem('hpv');
            
            // If pageVisited is null of false
            if(!pageVisited){
                let metadata = await getUserMetaData();
                try{
                    await axios.post('/home_page_visits.json', metadata);
                    window.localStorage.setItem('hpv', true);
                }catch(err){
                    window.localStorage.setItem('hpv', false );
                }
            }
        })();
    },[])


    // This will cause unnecessary re rendering cycles.
    // setTimeout( () => {
    //             console.log('Updating counter...');
    //             if(counter < greetings.length - 1)
    //                 setCounter(counter + 1);
    //             else   
    //                 setCounter(0);
    //             }
    //     , 3000);

    return (

        <div id="content">
            <h1 id="greeting">{greetings[counter]}</h1>
            <pre id="message">{`
                Hi there, my name is Khilesh, I'm a fullstack developer.
                I enjoy working with various technologies and exploring how things work.
                
                You can see the technologies I like using`} {techlink} 
                {`
                I would love to help you achieve your goals using my skills.
                
                Let me know if you have any interesting opportunities. 
                You can contact me `}{contactlink}</pre>
                
                <div id="ContactCard"> <ContactCard showEmail={false}/> </div>
        </div>
    )
}

// (function changeGreeting(){
    // const greetings = ['Bonjour', 'नमस्ते', 'Hola', 'こんにちは', '你好', 'Hallo'];
    // let counter = 0;

    // console.log('starting setInterval()');
    // setInterval( () => {
    //     document.querySelector('#greeting').innerHTML = greetings[counter];
    //     if(counter < greetings.length-1)
    //         counter++;
    //     else 
    //         counter = 0;
    // }, 3000);
    // })();