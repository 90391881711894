// import logo from './logo.svg';
import './App.css';
import {Switch, Route } from 'react-router-dom';
import Navigation from './component/Navigation/Navigation';
import Greetings from './container/Greetings/Greetings';
import Projects from './component/Projects/Projects';
import Contact from './component/Contact/Contact';
import Skills from './component/Skills/Skills';

function App() {

  let routes = (
    <Switch>
      <Route path="/tech" component={Skills}/>
      <Route path="/projects" component={Projects}/>
      <Route path="/contact" component={Contact}/>
      <Route path="/" exact component={Greetings}/>
    </Switch>
  )

  return (

      <div className="App">
          <div id="NavArea"> 
            <Navigation/>
          </div>
          <div id="ContentArea">
            {routes}
          </div>
      </div>
  );
}

export default App;
