import axios from 'axios';

const getUserMetaData = async () => {
        let curDate, platform, userAgent, screenRes, data; 
            try{
                let res = await axios.get('https://geoip-db.com/json/');
                data = res.data;
                // console.log('Geodata from utility');
                // console.log(data);
            } catch(err) {
                data = 'Error collecting geolocation data';
            }
            curDate = Date().toLocaleString();
            screenRes = `${window.screen.width}x${window.screen.height}`;
            platform = navigator.platform; 
            userAgent = navigator.userAgent;

            // console.log('Data ');
            // console.log(data);
            
            return  {
                date: curDate,
                screen_res: screenRes,
                platform: platform,
                user_agent: userAgent,
                geo_data: data,
            }
}

export default getUserMetaData; 