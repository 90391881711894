import React from 'react';
import classes from './MessageACK.module.css';

const MessageACK = (props) => {
    let errorStyle= {
    }
    let msgContent = ( 
        <>
        <h3 className={classes.MsgHeader}>Message Sent:</h3>
        <span className={classes.MsgACK}>Thanks for contacting, I'll try to get back to you as soon as possible.</span>
        </>
    );
    if(!props.submitSuccess){
        errorStyle = { backgroundColor: 'red'};
        msgContent = (
            <>
                <h3 className={classes.MsgHeader}>Error:</h3>
                <span className={classes.MsgACK}>Oops, something went wrong, please try again later.</span>
            </>
        )
    }

    return (
        <div className={classes.MsgDiv} style={errorStyle}>
            {msgContent} 
            <button onClick={props.onBtnClick} className={classes.BtnOK}>OK</button>
        </div>

    )
}

export default MessageACK;