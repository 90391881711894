import React from 'react';
import './NavItem.css';
import {Link} from 'react-router-dom';

const NavItem =  props => {
    return (
        <div id="nav-item">
           <img id="image" src={props.imagePath} alt=""/> 
            <Link to={props.link}> <span id="nav-text"> {props.text}</span></Link>
        </div>
    )
}

export default  NavItem;