import React from 'react';
import NavItem from './NavItem/NavItem';
import Images from '../../res/images/images';

const navigation = () => {

    const navText= [
        { 
            text: 'Home',
            imagePath: Images.home,
            link: '/'
        }, 
        {
            text :'Tech',
            imagePath: Images.cpu,
            link: '/tech'
        }, 
        {
            text :'Projects',
            imagePath: Images.hammer,
            link: '/projects'
        }, 
        {
            text:'Contact',
            imagePath: Images.email,
            link: '/contact'
        }
    ];

    let NavItems = navText.map( obj=> {
        return  <NavItem link={obj.link} imagePath={obj.imagePath} text={obj.text}/>
    })
    return (
        <>
            {NavItems}
        </>
    );
}

export default navigation;
