import homepage from './004-homepage-3.svg';
import hammer from './005-hammer-1.svg';
import cpu from './035-cpu-1.svg';
import email from './001-email.svg';
import python from './python-seeklogo.com.svg';
import react from './react.png';
import html from './html5-silver.png';
import css from './CSS3_white.webp';
import js from './JavaScript-logo.png';
import redux from './Redux-white-text.jpg';
import firebase from './firebase.jpg'
import django from './django-logo-negative.png';
import docker from './docker-white-text.png';
import rest from './Rest API.png';
import express from './express-white-text.png';
import android from './Android-Logo-green.png';
import reactnative from './react-native-logo-884x1024.png';
import git from './1280px-Git-logo.svg.png';
import linux from './kisspng-penguin-logo-linux-brand-font-difference-between-linux-and-window-operating-syst-5b6d4985f41774.9466845715338889019998.png';
import bash from './bash-logo.jpg';
import vscode from './visualstudio-code.png';
import vim from './1022px-Vimlogo.svg.png';
import tmux from './1280px-Tmux_logo.svg.png';
import node from './5hl8i764o3f1rc624qos5ukags.png';
import csharp from './c-sharp-c-logo-02F17714BA-seeklogo.com.png';
import cpp from './1200px-ISO_C++_Logo.svg.png';
import java from './1200px-Java_programming_language_logo.svg.png';
import clang from './c-programming-569564.webp';
import php from './php_PNG7.png';
import github from './5de2625ab4b90fb1868f1c33edfb88e6.gif';
import cpgif from './ezgif-2-316ffd1dac0d.gif';
import linkedin from './social-linkedin-circle-512.webp';
import hackerrank from './HackerRank_logo.png';




const images = {
    hackerrank: hackerrank,
    linkedin: linkedin,
    cpgif: cpgif,
    github: github,
    csharp: csharp,
    cpp: cpp, 
    java: java, 
    clang: clang, 
    php:php,
    node: node,
    firebase:firebase,
    django:django ,
    docker:docker ,
    rest :rest,
    express :express,
    android: android,
    reactnative: reactnative,
    git: git, 
    linux: linux, 
    bash: bash, 
    vscode: vscode, 
    vim: vim, 
    tmux : tmux,
    js: js,
    css: css,
    home: homepage,
    hammer: hammer,
    cpu: cpu,
    email: email,
    react: react,
    python: python,
    html: html,
    redux,
}

export default images;
