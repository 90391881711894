import React from 'react';
import './TechCard.css';

const TechCard = (props) => {
    let id = 'CardImg'; 

    if(props.className !== undefined){
        return <img src={props.image} alt={props.altText} className={props.className} style={props.style}></img>
    }

    return (
        <div id="Card">
            <img src={props.image} alt={props.altText} id={id} style={props.style}></img>
            {/* <span id="CardText">{props.text}</span> */}
        </div>
    )
}

export default TechCard;
